#footer {
    min-width: 200px;
    padding: 40px 0 50px 0;
    background-color: rgba(255, 201, 14, 1);
    display: flex;
    justify-content: center;
}

.footer-container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-logo-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-logo-text {
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    line-height: 140%;
    font-family: Jaro;
    font-size: 36px;
    letter-spacing: 0.1px;
}

.footer-link-box {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-email-link {
    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
}

.footer-copyright {
    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
}
