.comment-list-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.comment-list-item-top {
    display: flex;
    align-items: center;
    gap: 8px;
}

.comment-list-item-profile-box {
    width: 32px;
    height: 32px;
}

.comment-list-item-profile-image {
    border-radius: 50% 50%;
    width: 100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
}

.comment-list-item-nickname {
    color: rgba(0, 0, 0, 0.7);

    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}

.comment-list-item-divider {
    color: rgba(0, 0, 0, 0.4);

    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}

.comment-list-item-time {
    color: rgba(0, 0, 0, 0.4);

    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}


.comment-list-item-content {
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
}
