#user-top-wrapper {
    padding: 40px 0 45px;
    display: flex;
    justify-content: center;
}

.user-top-container {
    width: 1200px;
    min-width: 1200px;
    display: flex;
    align-items: center;
    gap:25px;
}

.user-top-my-profile-image-box {
    border-radius: 50px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(217, 217, 217, 1);
    cursor: pointer;
}

.user-top-profile-image {
    border-radius: 50px;
    width: 100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
}

.user-top-profile-image-box {
    border-radius: 50px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(217, 217, 217, 1);

    background-position: 50% 50%;
    background-size: 100% 100%;
}

.user-top-info-box {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.user-top-info-nickname-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-top-info-nickname-input {
    all: unset;
    width: auto;
    box-sizing: border-box;

    border-radius: 5px;
    font-family: Pretendard-Regular;
    color: rgba(0, 0, 0, 1);
    background-color: khaki;
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
}

.user-top-info-nickname {
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
}

.user-top-info-email {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

#user-bottom-wrapper {
    padding: 40px 0 45px;
    min-height: 880px;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
}

.user-bottom-container {
    width: 1200px;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-bottom-title {
    color: rgba(0, 0, 0, 1);

    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
}

.user-bottom-contents-box {
    width: 100%;
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 24px;
}

.user-bottom-contents {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.user-bottom-contents-nothing {
    width: 100%;
    height: 76px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(0, 0, 0, 0.4);

    font-size: 24px;
    font-weight: 500;
    line-height: 140%;
}

.user-bottom-side-box {
    grid-column: 2 / 3;
}

.user-bottom-side-card {
    width: 100%;
    height: 76px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.user-bottom-side-card:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.user-bottom-side-container {
    display: flex;
    align-items: center;
    gap: 6px;
}

.user-bottom-side-text {
    color: rgba(0, 0, 0, 1);

    font-size: 18px;
    font-weight: 500;
    line-height: 140%;

}

.user-bottom-pagination-box {
    margin-top: 60px;
    display: flex;
    justify-content: center;
}


