.inputbox {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputbox-label {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.inputbox-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 11px 16px 11px 0px;

    display: flex;
    align-items: center;
}

.inputbox-container-error {
    border-bottom: 1px solid rgba(255, 0, 0, 0.7);
    padding: 11px 16px 11px 0px;

    background-color: rgba(250, 250, 240, 1);

    display: flex;
    align-items: center;
}

.input {
    border: none;
    background: none;
    outline: none;

    flex: 1;

    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

.inputbox-message {
    color: rgba(255, 0, 0, 0.7);
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
}
