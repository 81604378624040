#header {
    padding: 20px 0;
    display: flex;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-container {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left-box {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.header-logo {
    color: rgba(255, 201, 14, 1);
    font-family: Jaro;
    font-size: 36px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.1px;
}

.header-right-box {
    display: flex;
    align-items: center;
    gap: 15px;
}

.header-search-input-box {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 43px;
    padding: 0 10px 0 12px;

    width: 250px;
    height: 30px;

    display: flex;
    align-items: center;
    gap: 5px;
}

.header-search-input {
    flex: 1;
    border: none;
    background: none;
    outline: none;

    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

