#board-detail-wrapper {
    padding: 100px 0;
    display: flex;
    justify-content: center;
}

.board-detail-container {
    width: 996px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#board-detail-top {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.board-detail-top-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-title {
    color: rgba(0, 0, 0, 1);

    font-size: 32px;
    font-weight: 600;
    line-height: 140%;
}

.board-detail-top-sub-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.board-detail-write-info-box {
    display: flex;
    align-items: center;
    gap: 8px;
}

.board-detail-writer-profile-image {
    border-radius: 32px;
    width: 32px;
    height: 32px;

    background-position: 50% 50%;
    background-size: 100% 100%;
}

.board-detail-writer-nickname {
    color: rgba(0, 0, 0, 0.7);

    font-size: 16px;
    font-weight: 500;
    line-height: 140%;

    cursor: pointer;
}

.board-detail-info-divider, .board-detail-write-date {
    color: rgba(0, 0, 0, 0.4);

    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
}

.board-detail-more-box {
    border-radius: 10px;
    position: absolute;
    top: -81px;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
}

.board-detail-update-button {
    width: 143px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-update-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.03);
}

.board-detail-delete-button {
    width: 143px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgba(255, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-delete-button:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.03);
}

.board-detail-top-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-main-text {
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;

    white-space: pre-wrap;
}

.board-detail-main-image {
    width: 100%;
}

#board-detail-bottom {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.board-detail-bottom-button-box {
    display: flex;
    gap: 12px;
}

.board-detail-bottom-button-group {
    display: flex;
    align-items: center;
    gap: 6px;
}

.board-detail-bottom-button-text {
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-bottom-favorite-box {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding:  20px 20px 40px;
}

.board-detail-bottom-favorite-container {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.board-detail-bottom-favorite-title {
    color: rgba(0, 0, 0, 1);

    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-bottom-favorite-content {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 30px;
}

.board-detail-bottom-comment-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-bottom-comment-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
}

.board-detail-bottom-comment-title {
    color: rgba(0, 0, 0, 1);

    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

.board-detail-bottom-comment-list-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.board-detail-bottom-comment-pagination-box {
    display: flex;
    justify-content: center;
}

.board-detail-bottom-comment-input-box {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.board-detail-bottom-comment-input-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.board-detail-bottom-comment-textarea {
    border: none;
    outline: none;
    background: none;
    resize: none;

    overflow: hidden;
    font-family: Pretendard-Regular;
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
}

.board-detail-bottom-comment-button-box {
    display: flex;
    justify-content: flex-end;
}







