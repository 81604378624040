.board-list-item {
    padding: 24px;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 30px;
    cursor: pointer;
}
.board-list-item:hover {
    background-color: rgba(0, 0, 0, 0.02);
}

.board-list-item-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.board-list-item-top {
    display: flex;
    align-items: center;
    gap: 8px;
}

.board-list-item-profile-box {
    width: 32px;
    height: 32px;
}

.board-list-item-profile-image {
    border-radius: 50% 50%;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: 100% 100%;
}

.board-list-item-write-box {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.board-list-item-nickname {
    color: rgba(0 ,0 ,0 ,0.7);
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
}

.board-list-item-write-date {
    color: rgba(0 ,0 ,0 ,0.4);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}

.board-list-item-middle {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.board-list-item-title {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

.board-list-item-content {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}

.board-list-item-bottom {
}

.board-list-item-counts {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    gap: 20px;
}

.board-list-item-counts-content {
    display: flex;
    line-height: 140%;
    justify-content: center;
    align-items: center;
}

.board-list-item-image-box {
    width: 152px;
    height: 152px;
}

.board-list-item-image{
    border-radius: 10px 10px;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: 100% 100%;
}


