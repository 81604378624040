.top-3-list-item {
    width: calc(384px - 24px - 24px);
    height: calc(508px - 24px - 24px);
    display: flex;
    flex-direction: column-reverse;

    cursor: pointer;
}

.top-3-list-item:hover {
    opacity: 0.9;
}

.top-3-list-item-main-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.top-3-list-item-top {
    display: flex;
    align-items: center;
    gap: 8px;
}

.top-3-list-item-profile-box {
    width: 32px;
    height: 32px;
}

.top-3-list-item-profile-image {
    border-radius: 50% 50%;
    width: 100%;
    height: 100%;

    background-position: 50% 50%;
    background-size: 100% 100%;
}

.top-3-list-item-write-box {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.top-3-list-item-nickname {
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
}

.top-3-list-item-write-date {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}

.top-3-list-item-middle {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.top-3-list-item-title {
    width: 100%;
    height: 22px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
}

.top-3-list-item-content {
    width: 100%;
    height: 17px;

    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}

.top-3-list-item-bottom {

}

.top-3-list-item-counts {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
}
